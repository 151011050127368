#GoogleLoginButton div[role="button"] {
  border: none;
  border-radius: 100px;
  box-shadow: var(--box-shadow-button);
  padding: 20px;
  height: 50px;
}

#GoogleLoginButton span {
  color: var(--color-primary);

  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}