/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.body1Size {
  height: var(--line-height-body1);
  width: var(--line-height-body1);
}

.body2Size {
  height: var(--line-height-body2);
  width: var(--line-height-body2);
}

.body3Size {
  height: var(--line-height-body3);
  width: var(--line-height-body3);
}

.fieldTitleSize {
  height: var(--line-height-fieldTitle);
  width: var(--line-height-fieldTitle);
}

.header1Size {
  height: var(--line-height-header1);
  width: var(--line-height-header1);
}

.header2Size {
  height: var(--line-height-header2);
  width: var(--line-height-header2);
}

.header3Size {
  height: var(--line-height-header3);
  width: var(--line-height-header3);
}

