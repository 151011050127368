placeholder {
  display: inline;
  border: 1px solid #ccc;
  color: #ccc;
}

placeholder:after {
  content: "☁";
  font-size: 200%;
  line-height: 0.1;
  font-weight: bold;
}

.prosemirror-editor-title {
  margin: auto;
  margin-bottom: 5px;
  max-width: 600px;
}

.prosemirror-input-label {
  min-height: 28px;
  min-width: 28px;
  background-image: url("../public/prosemirror/menu/inactive/image.svg");
  display: inline-block;
  cursor: pointer;
}

.ProseMirror {
  background-color: var(--color-white);
  border: 1px solid #e6e8f0;
  border-radius: 12px;
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  min-height: 100px;
  max-width: 600px;
}

.ProseMirror[contenteditable="true"] {
  max-height: 300px;
  overflow-y: auto;
}

.ProseMirror[contenteditable="true"] img {
  max-width: 100%;
}

.hideCloze [data-title="Fill-in-the-blank"] {
  display: none;
}

.showCloze [data-title="Fill-in-the-blank"] {
  display: block;
}

@media only screen and (max-width: 768px) {
  .prosemirror-editor-title {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    width: 100%;
  }

  .ProseMirror {
    box-sizing: border-box;
    width: 100%;
  }
}