:root {
  /* body1 */
  --font-family-body1: var(--work-sans), sans-serif;
  --font-size-body1: 16px;
  --font-weight-body1: 400;
  --line-height-body1: 20px;

  /* body2 */
  --font-family-body2: var(--work-sans), sans-serif;
  --font-size-body2: 14px;
  --font-weight-body2: 400;
  --line-height-body2: 18px;

  /* body3 */
  --font-family-body3: var(--work-sans), sans-serif;
  --font-size-body3: 12px;
  --font-weight-body3: 400;
  --line-height-body3: 16px;

  /* fieldTitle */
  --font-family-fieldTitle: var(--work-sans), sans-serif;
  --font-size-fieldTitle: 14px;
  --font-weight-fieldTitle: 500;

  /* header1 */
  --font-family-header1: var(--dm-sans), serif;
  --font-size-header1: 36px;
  --font-weight-header1: 500;
  --line-height-header1: 46px;

  /* header2 */
  --font-family-header2: var(--dm-sans), sans-serif;
  --font-size-header2: 26px;
  --font-weight-header2: 500;
  --line-height-header2: 28px;

  /* header3 */
  --font-family-header3: var(--dm-sans), sans-serif;
  --font-size-header3: 20px;
  --font-weight-header3: 500;
  --line-height-header3: 24px;
}

@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 15px;
    --line-height-body1: 19px;

    /* body2 */
    --font-size-body2: 13px;
    --line-height-body2: 17px;

    /* body3 */
    --font-size-body3: 11px;
    --line-height-body3: 15px;

    /* header1 */
    --font-size-header1: 32px;
    --line-height-header1: 42px;

    /* header2 */
    --font-size-header2: 22px;
    --line-height-header2: 26px;

    /* header3 */
    --font-size-header3: 18px;
    --line-height-header3: 22px;
  }
}

@media only screen and (max-width: 640px) {
  :root {
    /* header1 */
    --font-size-header1: 26px;
    --line-height-header1: 34px;
  }
}