.ant-drawer {
  width: 100% !important;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-top.ant-drawer-open {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-bottom .ant-drawer-content {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer-body {
  background-color: var(--color-floatingElement);
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
}

.ant-notification-notice {
  width: auto;
}

.ant-notification-notice {
  background-color: var(--color-websiteBackground);
}

.ant-notification-notice-icon {
  /* Makes it so notif icon and title are center aligned */
  margin-top: 2px;
}

.ant-notification-notice-message {
  color: var(--color-primary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.ant-notification-notice-description {
  color: var(--color-secondary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 400;
  margin-top: 8px;
  max-width: 40ch;
}

.ant-notification-notice-description:empty {
  display: none;
}

.anticon-info-circle path {
  fill: var(--color-primary);
}

.ant-notification-notice {
  border-radius: 12px !important;
  padding: 20px 24px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner {
  background-color: var(--color-websiteBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  overflow: hidden;
  padding: 0px !important;
}

.ant-popover-inner-content {
  max-height: 90vh;
  overflow-y: auto;
  padding: 0px;
}

.ant-tooltip {
  max-width: none;
}

.ant-tooltip-inner {
  border-radius: 16px;
  padding: 12px 16px !important;
}
body {
  background-color: var(--color-websiteBackground);
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

html {
  height: 100%;
}

#__next {
  height: 100%;
}

.buttonPlain {
  border-style: none;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.buttonPlain:hover {
  cursor: pointer;
}

/* See https://stackoverflow.com/questions/1833734/display-inline-block-extra-margin/8262649#8262649 */
.hideText {
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
}

.textTooltipUnderline {
  display: inline;
  -webkit-text-decoration: underline dashed var(--color-secondary);
          text-decoration: underline dashed var(--color-secondary);
  text-underline-offset: 2px;
}

.uppercase {
  text-transform: uppercase;
}

/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorErrorDisabled {
  background-color: var(--color-errorDisabled);
}

.backgroundColorErrorHover {
  background-color: var(--color-errorHover);
}

.backgroundColorFacebook {
  background-color: var(--color-facebook);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorGoogle {
  background-color: var(--color-google);
}

.backgroundColorHard {
  background-color: var(--color-hard);
}

.backgroundColorHardHover {
  background-color: var(--color-hardHover);
}

.backgroundColorHighlight {
  background-color: var(--color-highlight);
}

.backgroundColorHighlightDisabled {
  background-color: var(--color-highlightDisabled);
}

.backgroundColorHighlightHover {
  background-color: var(--color-highlightHover);
}

.backgroundColorLightPurple {
  background-color: var(--color-lightPurple);
}

.backgroundColorLightPurple2 {
  background-color: var(--color-lightPurple2);
}

.backgroundColorLightPurpleHover {
  background-color: var(--color-lightPurpleHover);
}

.backgroundColorMauve {
  background-color: var(--color-mauve);
}

.backgroundColorMauveHover {
  background-color: var(--color-mauveHover);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorNotReviewed {
  background-color: var(--color-notReviewed);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorProgressBar {
  background-color: var(--color-progressBar);
}

.backgroundColorSearchHighlight {
  background-color: var(--color-searchHighlight);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorSeparator {
  background-color: var(--color-separator);
}

.backgroundColorSeparatorDisabled {
  background-color: var(--color-separatorDisabled);
}

.backgroundColorSeparatorHover {
  background-color: var(--color-separatorHover);
}

.backgroundColorSeparatorWithShadow {
  background-color: var(--color-separatorWithShadow);
}

.backgroundColorSuccess {
  background-color: var(--color-success);
}

.backgroundColorSuccessDisabled {
  background-color: var(--color-successDisabled);
}

.backgroundColorSuccessHover {
  background-color: var(--color-successHover);
}

.backgroundColorTagBackground {
  background-color: var(--color-tagBackground);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}


/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorError {
  color: var(--color-error);
}

.colorErrorDisabled {
  color: var(--color-errorDisabled);
}

.colorErrorHover {
  color: var(--color-errorHover);
}

.colorFacebook {
  color: var(--color-facebook);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorGoogle {
  color: var(--color-google);
}

.colorHard {
  color: var(--color-hard);
}

.colorHardHover {
  color: var(--color-hardHover);
}

.colorHighlight {
  color: var(--color-highlight);
}

.colorHighlightDisabled {
  color: var(--color-highlightDisabled);
}

.colorHighlightHover {
  color: var(--color-highlightHover);
}

.colorLightPurple2 {
  color: var(--color-lightPurple2);
}

.colorLightPurple {
  color: var(--color-lightPurple);
}

.colorLightPurpleHover {
  color: var(--color-lightPurpleHover);
}

.colorMauve {
  color: var(--color-mauve);
}

.colorMauveHover {
  color: var(--color-mauveHover);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorNotReviewed {
  color: var(--color-notReviewed);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorProgressBar {
  color: var(--color-progressBar);
}

.colorSearchHighlight {
  color: var(--color-searchHighlight);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorSeparator {
  color: var(--color-separator);
}

.colorSeparatorDisabled {
  color: var(--color-separatorDisabled);
}

.colorSeparatorHover {
  color: var(--color-separatorHover);
}

.colorSeparatorWithShadow {
  color: var(--color-separatorWithShadow);
}

.colorSuccess {
  color: var(--color-success);
}

.colorSuccessDisabled {
  color: var(--color-successDisabled);
}

.colorSuccessHover {
  color: var(--color-successHover);
}

.colorTagBackground {
  color: var(--color-tagBackground);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}


:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-error: #f04c4c;
  --color-errorDisabled: rgba(240, 76, 76, 0.36);
  --color-errorHover: #cf3232;
  --color-facebook: #5e81dc;
  --color-ghost: #bfbfbf;
  --color-google: #7bb0ff;
  --color-hard: #fc881c;
  --color-hardHover: #e17008;
  --color-highlight: #5c61df;
  --color-highlightDisabled: rgba(92, 97, 223, 0.36);
  --color-highlightHover: #2f35c0;
  --color-lightPurple2: #eaecff;
  --color-lightPurple: #f5f6fd;
  --color-lightPurpleHover: rgba(92, 97, 223, 0.16);
  --color-mauve: #8b90f8;
  --color-mauveHover: #767beb;
  --color-menuPopup: #fff;
  --color-notReviewed: #e0e3f5;
  --color-primary: #1f1f1f;
  --color-progressBar: #8f92e0;
  --color-searchHighlight: #96E7CE;
  --color-secondary: #808080;
  --color-separator: #e6e8f0;
  --color-separatorDisabled: rgba(230, 232, 240, 0.36);
  --color-separatorHover: #D9DCE9;
  --color-separatorWithShadow: #f1f1f1;
  --color-success: #4acea6;
  --color-successDisabled: rgba(56, 203, 159, 0.36);
  --color-successHover: #1bb98a;
  --color-tagBackground: #e0e3f5;
  --color-tertiary: #dedde5;
  --color-websiteBackground: #fcfcff;
  --color-white: #ffffff; 
}

:root {
  --color-backgroundOverlay-darkMode: rgba(0, 0, 0, 0.3);
  --color-error-darkMode: #f04c4c;
  --color-errorDisabled: rgba(240, 76, 76, 0.36);
  --color-errorHover-darkMode: #cf3232;
  --color-facebook-darkMode: #5e81dc;
  --color-ghost-darkMode: #bfbfbf;
  --color-google-darkMode: #7bb0ff;
  --color-hard-darkMode: #fc881c;
  --color-hardHover-darkMode: #e17008;
  --color-highlight-darkMode: #5c61df;
  --color-highlightDisabled: rgba(92, 97, 223, 0.36);
  --color-highlightHover-darkMode: #2f35c0;
  --color-lightPurple-darkMode: #f5f6fd;
  --color-lightPurple2-darkMode: #eaecff;
  --color-lightPurpleHover: rgba(92, 97, 223, 0.16);
  --color-mauve-darkMode: #8b90f8;
  --color-mauveHover-darkMode: #767beb;
  --color-menuPopup-darkMode: #fff;
  --color-notReviewed-darkMode: #e0e3f5;
  --color-primary-darkMode: #1f1f1f;
  --color-progressBar-darkMode: #8f92e0;
  --color-searchHighlight-darkMode: #96E7CE;
  --color-secondary-darkMode: #808080;
  --color-separator-darkMode: #e6e8f0;
  --color-separatorDisabled: rgba(230, 232, 240, 0.36);
  --color-separatorHover-darkMode: #d9dce9;
  --color-separatorWithShadow-darkMode: #f1f1f1;
  --color-success-darkMode: #4acea6;
  --color-successDisabled: rgba(56, 203, 159, 0.36);
  --color-successHover-darkMode: #1bb98a;
  --color-tagBackground-darkMode: #e0e3f5;
  --color-tertiary-darkMode: #dedde5;
  --color-websiteBackground-darkMode: #fcfcff;
  --color-white-darkMode: #ffffff; 
}

:root {
  --color-backgroundOverlay-lightMode: rgba(0, 0, 0, 0.3);
  --color-error-lightMode: #f04c4c;
  --color-errorDisabled: rgba(240, 76, 76, 0.36);
  --color-errorHover-lightMode: #cf3232;
  --color-facebook-lightMode: #5e81dc;
  --color-ghost-lightMode: #bfbfbf;
  --color-google-lightMode: #7bb0ff;
  --color-hard-lightMode: #fc881c;
  --color-hardHover-lightMode: #e17008;
  --color-highlight-lightMode: #5c61df;
  --color-highlightDisabled: rgba(92, 97, 223, 0.36);
  --color-highlightHover-lightMode: #2f35c0;
  --color-lightPurple-lightMode: #f5f6fd;
  --color-lightPurple2-lightMode: #eaecff;
  --color-lightPurpleHover: rgba(92, 97, 223, 0.16);
  --color-mauve-lightMode: #8b90f8;
  --color-mauveHover-lightMode: #767beb;
  --color-menuPopup-lightMode: #fff;
  --color-notReviewed-lightMode: #e0e3f5;
  --color-primary-lightMode: #1f1f1f;
  --color-progressBar-lightMode: #8f92e0;
  --color-searchHighlight-lightMode: #96E7CE;
  --color-secondary-lightMode: #808080;
  --color-separator-lightMode: #e6e8f0;
  --color-separatorDisabled: rgba(230, 232, 240, 0.36);
  --color-separatorHover-lightMode: #d9dce9;
  --color-separatorWithShadow-lightMode: #f1f1f1;
  --color-success-lightMode: #4acea6;
  --color-successDisabled: rgba(56, 203, 159, 0.36);
  --color-successHover-lightMode: #1bb98a;
  --color-tagBackground-lightMode: #e0e3f5;
  --color-tertiary-lightMode: #dedde5;
  --color-websiteBackground-lightMode: #fcfcff;
  --color-white-lightMode: #ffffff; 
}

/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.body1Font {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-body1);
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1MediumFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1SemiBoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1BoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body2Font {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: var(--font-weight-body2);
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2MediumFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2SemiBoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2BoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body3Font {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: var(--font-weight-body3);
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3MediumFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3SemiBoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.body3BoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
}

.fieldTitleFont {
  font-family: var(--font-family-fieldTitle);
  font-size: var(--font-size-fieldTitle);
  font-weight: var(--font-weight-fieldTitle);
  letter-spacing: var(--letter-spacing-fieldTitle);
  line-height: var(--line-height-fieldTitle);
}

.header1Font {
  font-family: var(--font-family-header1);
  font-size: var(--font-size-header1);
  font-weight: var(--font-weight-header1);
  letter-spacing: var(--letter-spacing-header1);
  line-height: var(--line-height-header1);
}

.header2Font {
  font-family: var(--font-family-header2);
  font-size: var(--font-size-header2);
  font-weight: var(--font-weight-header2);
  letter-spacing: var(--letter-spacing-header2);
  line-height: var(--line-height-header2);
}

.header3Font {
  font-family: var(--font-family-header3);
  font-size: var(--font-size-header3);
  font-weight: var(--font-weight-header3);
  letter-spacing: var(--letter-spacing-header3);
  line-height: var(--line-height-header3);
}


/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.body1Size {
  height: var(--line-height-body1);
  width: var(--line-height-body1);
}

.body2Size {
  height: var(--line-height-body2);
  width: var(--line-height-body2);
}

.body3Size {
  height: var(--line-height-body3);
  width: var(--line-height-body3);
}

.fieldTitleSize {
  height: var(--line-height-fieldTitle);
  width: var(--line-height-fieldTitle);
}

.header1Size {
  height: var(--line-height-header1);
  width: var(--line-height-header1);
}

.header2Size {
  height: var(--line-height-header2);
  width: var(--line-height-header2);
}

.header3Size {
  height: var(--line-height-header3);
  width: var(--line-height-header3);
}


:root {
  /* body1 */
  --font-family-body1: var(--work-sans), sans-serif;
  --font-size-body1: 16px;
  --font-weight-body1: 400;
  --line-height-body1: 20px;

  /* body2 */
  --font-family-body2: var(--work-sans), sans-serif;
  --font-size-body2: 14px;
  --font-weight-body2: 400;
  --line-height-body2: 18px;

  /* body3 */
  --font-family-body3: var(--work-sans), sans-serif;
  --font-size-body3: 12px;
  --font-weight-body3: 400;
  --line-height-body3: 16px;

  /* fieldTitle */
  --font-family-fieldTitle: var(--work-sans), sans-serif;
  --font-size-fieldTitle: 14px;
  --font-weight-fieldTitle: 500;

  /* header1 */
  --font-family-header1: var(--dm-sans), serif;
  --font-size-header1: 36px;
  --font-weight-header1: 500;
  --line-height-header1: 46px;

  /* header2 */
  --font-family-header2: var(--dm-sans), sans-serif;
  --font-size-header2: 26px;
  --font-weight-header2: 500;
  --line-height-header2: 28px;

  /* header3 */
  --font-family-header3: var(--dm-sans), sans-serif;
  --font-size-header3: 20px;
  --font-weight-header3: 500;
  --line-height-header3: 24px;
}

@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 15px;
    --line-height-body1: 19px;

    /* body2 */
    --font-size-body2: 13px;
    --line-height-body2: 17px;

    /* body3 */
    --font-size-body3: 11px;
    --line-height-body3: 15px;

    /* header1 */
    --font-size-header1: 32px;
    --line-height-header1: 42px;

    /* header2 */
    --font-size-header2: 22px;
    --line-height-header2: 26px;

    /* header3 */
    --font-size-header3: 18px;
    --line-height-header3: 22px;
  }
}

@media only screen and (max-width: 640px) {
  :root {
    /* header1 */
    --font-size-header1: 26px;
    --line-height-header1: 34px;
  }
}
:root {
  --box-shadow-dark: 0px 0px 12px rgba(65, 68, 92, 0.24);

  --box-shadow-button: 0px 0px 12px rgba(171, 171, 171, 0.3);

  --drop-shadow-button: drop-shadow(0px 0px 24px rgba(78, 76, 107, 0.12));
}
:root {
  --z-index-cardBulkEditPopup: 4;
  --z-index-bottomTabs: 2;
  --z-index-backgroundOverlay: 3;
  --z-index-modal: 3;
}

/**
    Name:       IDEA default theme
    From IntelliJ IDEA by JetBrains
 */

.cm-s-idea span.cm-meta { color: #808000; }
.cm-s-idea span.cm-number { color: #0000FF; }
.cm-s-idea span.cm-keyword { line-height: 1em; font-weight: bold; color: #000080; }
.cm-s-idea span.cm-atom { font-weight: bold; color: #000080; }
.cm-s-idea span.cm-def { color: #000000; }
.cm-s-idea span.cm-variable { color: black; }
.cm-s-idea span.cm-variable-2 { color: black; }
.cm-s-idea span.cm-variable-3, .cm-s-idea span.cm-type { color: black; }
.cm-s-idea span.cm-property { color: black; }
.cm-s-idea span.cm-operator { color: black; }
.cm-s-idea span.cm-comment { color: #808080; }
.cm-s-idea span.cm-string { color: #008000; }
.cm-s-idea span.cm-string-2 { color: #008000; }
.cm-s-idea span.cm-qualifier { color: #555; }
.cm-s-idea span.cm-error { color: #FF0000; }
.cm-s-idea span.cm-attribute { color: #0000FF; }
.cm-s-idea span.cm-tag { color: #000080; }
.cm-s-idea span.cm-link { color: #0000FF; }
.cm-s-idea .CodeMirror-activeline-background { background: #FFFAE3; }

.cm-s-idea span.cm-builtin { color: #30a; }
.cm-s-idea span.cm-bracket { color: #cc7; }
.cm-s-idea  { font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;}


.cm-s-idea .CodeMirror-matchingbracket { outline:1px solid grey; color:black !important; }

.CodeMirror-hints.idea {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #616569;
  background-color: #ebf3fd !important;
}

.CodeMirror-hints.idea .CodeMirror-hint-active {
  background-color: #a2b8c9 !important;
  color: #5c6065 !important;
}
/* Styles the HTML displayed by the CardDisplay component */

.cardDisplay hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px dashed #e6e8f0;
  margin-bottom: 40px;
  margin-left: -40px;
  margin-top: 40px;
  width: 782px;
}

.cardDisplay img {
  max-width: 100%;
}
/* Styles the HTML displayed by the CardFrontColumn component */

.cardFront hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px dashed #e6e8f0;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-top: 20px;
  width: 100%;
}

.cardFront img {
  max-width: 100%;
}

/*
 * Can't use .cardFrontNotExpanded *, it will mess up display of code blocks.
 */
.cardFrontNotExpanded > *,
.cardFrontNotExpanded li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cardFrontNotExpanded ul,
.cardFrontNotExpanded ol {
  list-style-position: inside;
  margin: 0px;
  overflow: hidden;
  padding-left: 0px;
}

.cardFrontNotExpanded pre {
  margin: 0px;
  /* Need !important to override inline style */
  padding: 0px !important;
}

.cardFront p:first-child {
  margin-top: 0px;
}

.cardFront p:last-child {
  margin-bottom: 0px;
}

.cardFront p:only-child {
  margin: 0px;
}

/* COPIED FROM THE CODEMIRROR MODULE */

/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  /* height: 300px; */
  color: var(--color-primary);
  direction: ltr;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: var(
    --color-white
  ); /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}
.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
  color: blue;
}
.cm-s-default .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}
.cm-s-default .cm-atom {
  color: #219;
}
.cm-s-default .cm-number {
  color: #164;
}
.cm-s-default .cm-def {
  color: #00f;
}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {
}
.cm-s-default .cm-variable-2 {
  color: #05a;
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #085;
}
.cm-s-default .cm-comment {
  color: #a50;
}
.cm-s-default .cm-string {
  color: #a11;
}
.cm-s-default .cm-string-2 {
  color: #f50;
}
.cm-s-default .cm-meta {
  color: #555;
}
.cm-s-default .cm-qualifier {
  color: #555;
}
.cm-s-default .cm-builtin {
  color: #30a;
}
.cm-s-default .cm-bracket {
  color: #997;
}
.cm-s-default .cm-tag {
  color: #170;
}
.cm-s-default .cm-attribute {
  color: #00c;
}
.cm-s-default .cm-hr {
  color: #999;
}
.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: var(--color-white);
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-feature-settings: "calt";
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

#GoogleLoginButton div[role="button"] {
  border: none;
  border-radius: 100px;
  box-shadow: var(--box-shadow-button);
  padding: 20px;
  height: 50px;
}

#GoogleLoginButton span {
  color: var(--color-primary);

  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}
.ProseMirror {
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0, none; /* the above doesn't seem to work in Edge */
  padding: 4px 14px;
  line-height: 1.2;
  outline: none;
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror li {
  position: relative;
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
  outline: none;
}

li.ProseMirror-selectednode:after {
  content: "";
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}
.ProseMirror-textblock-dropdown {
  min-width: 3em;
}

.ProseMirror-menu {
  margin: 0 -4px;
  line-height: 1;
}

.ProseMirror-tooltip .ProseMirror-menu {
  width: -moz-fit-content;
  width: fit-content;
  white-space: pre;
}

.ProseMirror-menuitem {
  height: 28px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.ProseMirror-menuitem:first-child {
  margin-left: 0;
}

.ProseMirror-menuitem:last-child {
  margin-right: 0;
}

.ProseMirror-menuseparator {
  border-right: 1px solid #ddd;
  margin-right: 3px;
}

.ProseMirror-menu-dropdown,
.ProseMirror-menu-dropdown-menu {
  font-size: 90%;
  white-space: nowrap;
}

.ProseMirror-menu-dropdown {
  vertical-align: 1px;
  cursor: pointer;
  position: relative;
  padding-right: 15px;
}

.ProseMirror-menu-dropdown-wrap {
  padding: 1px 0 1px 4px;
  display: inline-block;
  position: relative;
}

.ProseMirror-menu-dropdown:after {
  content: "";
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 2px);
}

.ProseMirror-menu-dropdown-menu,
.ProseMirror-menu-submenu {
  position: absolute;
  background: var(--color-white);
  color: #666;
  border: 1px solid #aaa;
  padding: 2px;
}

.ProseMirror-menu-dropdown-menu {
  z-index: 15;
  min-width: 6em;
}

.ProseMirror-menu-dropdown-item {
  cursor: pointer;
  padding: 2px 8px 2px 4px;
}

.ProseMirror-menu-dropdown-item:hover {
  background: #f2f2f2;
}

.ProseMirror-menu-submenu-wrap {
  position: relative;
  margin-right: -4px;
}

.ProseMirror-menu-submenu-label:after {
  content: "";
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid currentColor;
  opacity: 0.6;
  position: absolute;
  right: 4px;
  top: calc(50% - 4px);
}

.ProseMirror-menu-submenu {
  display: none;
  min-width: 4em;
  left: 100%;
  top: -3px;
}

.ProseMirror-menu-active {
  background: #c7c9f4;
  border-radius: 4px;
}

.ProseMirror-menu-disabled {
  opacity: 0.3;
}

.ProseMirror-menu-submenu-wrap:hover .ProseMirror-menu-submenu,
.ProseMirror-menu-submenu-wrap-active .ProseMirror-menu-submenu {
  display: block;
}

.ProseMirror-menubar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  position: relative;
  color: #666;
  height: 28px;
  top: 0;
  left: 0;
  right: 0;
  /* border-bottom: 1px solid silver; */
  /* background: var(--white-color); */
  z-index: 10;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.ProseMirror-icon {
  display: inline-block;
  /* line-height: 0.8; */
  /* vertical-align: -2px; Compensate for padding */
  /* padding: 2px 2px; */
  cursor: pointer;
  height: 28px;
  position: relative;
  width: 28px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ProseMirror-icon::after {
  content: "";
  opacity: 0;
}

.ProseMirror-icon:active::after {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--box-shadow-dark);
  color: var(--color-primary);
  content: attr(data-title);
  font-family: var(--work-sans), sans-serif;
  font-size: 14px;
  left: 50%;
  padding: 8px 10px;
  position: absolute;
  top: 34px;
  opacity: 1;
  text-align: center;
  transform: translateX(-50%);
  transition: opacity 0s linear 0.5s;
  white-space: nowrap;
}

@media (hover: hover) {
  .ProseMirror-icon:hover::after {
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: var(--box-shadow-dark);
    color: var(--color-primary);
    content: attr(data-title);
    font-family: var(--work-sans), sans-serif;
    font-size: 14px;
    left: 50%;
    padding: 8px 10px;
    position: absolute;
    top: 34px;
    opacity: 1;
    text-align: center;
    transform: translateX(-50%);
    transition: opacity 0s linear 0.5s;
    white-space: nowrap;
  }
}

.ProseMirror-menu-disabled.ProseMirror-icon {
  cursor: default;
}

.ProseMirror-icon svg {
  fill: currentColor;
  height: 1em;
}

.ProseMirror-icon span {
  vertical-align: text-top;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
/* Add space around the hr to make clicking it easier */

.ProseMirror-example-setup-style hr {
  padding: 2px 10px;
  border: none;
  margin: 1em 0;
}

.ProseMirror-example-setup-style hr:after {
  content: "";
  display: block;
  height: 1px;
  background-color: silver;
  line-height: 2px;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-left: 30px;
}

.ProseMirror blockquote {
  padding-left: 1em;
  border-left: 3px solid #eee;
  margin-left: 0;
  margin-right: 0;
}

.ProseMirror-example-setup-style img {
  cursor: default;
}

.ProseMirror-prompt {
  background: var(--color-white);
  padding: 5px 10px 5px 15px;
  border: 1px solid silver;
  position: fixed;
  border-radius: 3px;
  z-index: 11;
  box-shadow: -0.5px 2px 5px rgba(0, 0, 0, 0.2);
}

.ProseMirror-prompt h5 {
  margin: 0;
  font-weight: normal;
  font-size: 100%;
  color: #444;
}

.ProseMirror-prompt input[type="text"],
.ProseMirror-prompt textarea {
  background: #eee;
  border: none;
  outline: none;
}

.ProseMirror-prompt input[type="text"] {
  padding: 0 4px;
}

.ProseMirror-prompt-close {
  position: absolute;
  left: 2px;
  top: 1px;
  color: #666;
  border: none;
  background: transparent;
  padding: 0;
}

.ProseMirror-prompt-close:after {
  content: "âœ•";
  font-size: 12px;
}

.ProseMirror-invalid {
  background: #ffc;
  border: 1px solid #cc7;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  min-width: 10em;
}

.ProseMirror-prompt-buttons {
  margin-top: 5px;
  display: none;
}

.ProseMirror p:first-child,
.ProseMirror h1:first-child,
.ProseMirror h2:first-child,
.ProseMirror h3:first-child,
.ProseMirror h4:first-child,
.ProseMirror h5:first-child,
.ProseMirror h6:first-child,
.ProseMirror pre {
  margin-top: 10px;
}

.ProseMirror p {
  margin-bottom: 1em;
}

placeholder {
  display: inline;
  border: 1px solid #ccc;
  color: #ccc;
}

placeholder:after {
  content: "☁";
  font-size: 200%;
  line-height: 0.1;
  font-weight: bold;
}

.prosemirror-editor-title {
  margin: auto;
  margin-bottom: 5px;
  max-width: 600px;
}

.prosemirror-input-label {
  min-height: 28px;
  min-width: 28px;
  background-image: url(/_next/static/media/image.71cb99f5.svg);
  display: inline-block;
  cursor: pointer;
}

.ProseMirror {
  background-color: var(--color-white);
  border: 1px solid #e6e8f0;
  border-radius: 12px;
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  min-height: 100px;
  max-width: 600px;
}

.ProseMirror[contenteditable="true"] {
  max-height: 300px;
  overflow-y: auto;
}

.ProseMirror[contenteditable="true"] img {
  max-width: 100%;
}

.hideCloze [data-title="Fill-in-the-blank"] {
  display: none;
}

.showCloze [data-title="Fill-in-the-blank"] {
  display: block;
}

@media only screen and (max-width: 768px) {
  .prosemirror-editor-title {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    width: 100%;
  }

  .ProseMirror {
    box-sizing: border-box;
    width: 100%;
  }
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_750548';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_750548';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_750548 {font-family: '__DM_Sans_750548', '__DM_Sans_Fallback_750548';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_cbc1de';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Work_Sans_Fallback_cbc1de';src: local("Arial");ascent-override: 82.98%;descent-override: 21.68%;line-gap-override: 0.00%;size-adjust: 112.07%
}.__className_cbc1de {font-family: '__Work_Sans_cbc1de', '__Work_Sans_Fallback_cbc1de';font-style: normal
}

