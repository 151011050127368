.ant-drawer {
  width: 100% !important;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-top.ant-drawer-open {
  animation: none !important;
  transition: none !important;
}

.ant-drawer-bottom .ant-drawer-content {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer-body {
  background-color: var(--color-floatingElement);
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
}

.ant-notification-notice {
  width: auto;
}

.ant-notification-notice {
  background-color: var(--color-websiteBackground);
}

.ant-notification-notice-icon {
  /* Makes it so notif icon and title are center aligned */
  margin-top: 2px;
}

.ant-notification-notice-message {
  color: var(--color-primary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.ant-notification-notice-description {
  color: var(--color-secondary);
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1) !important;
  font-weight: 400;
  margin-top: 8px;
  max-width: 40ch;
}

.ant-notification-notice-description:empty {
  display: none;
}

.anticon-info-circle path {
  fill: var(--color-primary);
}

.ant-notification-notice {
  border-radius: 12px !important;
  padding: 20px 24px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner {
  background-color: var(--color-websiteBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  overflow: hidden;
  padding: 0px !important;
}

.ant-popover-inner-content {
  max-height: 90vh;
  overflow-y: auto;
  padding: 0px;
}

.ant-tooltip {
  max-width: none;
}

.ant-tooltip-inner {
  border-radius: 16px;
  padding: 12px 16px !important;
}