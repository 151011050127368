/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorError {
  color: var(--color-error);
}

.colorErrorDisabled {
  color: var(--color-errorDisabled);
}

.colorErrorHover {
  color: var(--color-errorHover);
}

.colorFacebook {
  color: var(--color-facebook);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorGoogle {
  color: var(--color-google);
}

.colorHard {
  color: var(--color-hard);
}

.colorHardHover {
  color: var(--color-hardHover);
}

.colorHighlight {
  color: var(--color-highlight);
}

.colorHighlightDisabled {
  color: var(--color-highlightDisabled);
}

.colorHighlightHover {
  color: var(--color-highlightHover);
}

.colorLightPurple2 {
  color: var(--color-lightPurple2);
}

.colorLightPurple {
  color: var(--color-lightPurple);
}

.colorLightPurpleHover {
  color: var(--color-lightPurpleHover);
}

.colorMauve {
  color: var(--color-mauve);
}

.colorMauveHover {
  color: var(--color-mauveHover);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorNotReviewed {
  color: var(--color-notReviewed);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorProgressBar {
  color: var(--color-progressBar);
}

.colorSearchHighlight {
  color: var(--color-searchHighlight);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorSeparator {
  color: var(--color-separator);
}

.colorSeparatorDisabled {
  color: var(--color-separatorDisabled);
}

.colorSeparatorHover {
  color: var(--color-separatorHover);
}

.colorSeparatorWithShadow {
  color: var(--color-separatorWithShadow);
}

.colorSuccess {
  color: var(--color-success);
}

.colorSuccessDisabled {
  color: var(--color-successDisabled);
}

.colorSuccessHover {
  color: var(--color-successHover);
}

.colorTagBackground {
  color: var(--color-tagBackground);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}

