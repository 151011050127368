/* Styles the HTML displayed by the CardFrontColumn component */

.cardFront hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px dashed #e6e8f0;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-top: 20px;
  width: 100%;
}

.cardFront img {
  max-width: 100%;
}

/*
 * Can't use .cardFrontNotExpanded *, it will mess up display of code blocks.
 */
.cardFrontNotExpanded > *,
.cardFrontNotExpanded li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cardFrontNotExpanded ul,
.cardFrontNotExpanded ol {
  list-style-position: inside;
  margin: 0px;
  overflow: hidden;
  padding-left: 0px;
}

.cardFrontNotExpanded pre {
  margin: 0px;
  /* Need !important to override inline style */
  padding: 0px !important;
}

.cardFront p:first-child {
  margin-top: 0px;
}

.cardFront p:last-child {
  margin-bottom: 0px;
}

.cardFront p:only-child {
  margin: 0px;
}
