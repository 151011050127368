/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorErrorDisabled {
  background-color: var(--color-errorDisabled);
}

.backgroundColorErrorHover {
  background-color: var(--color-errorHover);
}

.backgroundColorFacebook {
  background-color: var(--color-facebook);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorGoogle {
  background-color: var(--color-google);
}

.backgroundColorHard {
  background-color: var(--color-hard);
}

.backgroundColorHardHover {
  background-color: var(--color-hardHover);
}

.backgroundColorHighlight {
  background-color: var(--color-highlight);
}

.backgroundColorHighlightDisabled {
  background-color: var(--color-highlightDisabled);
}

.backgroundColorHighlightHover {
  background-color: var(--color-highlightHover);
}

.backgroundColorLightPurple {
  background-color: var(--color-lightPurple);
}

.backgroundColorLightPurple2 {
  background-color: var(--color-lightPurple2);
}

.backgroundColorLightPurpleHover {
  background-color: var(--color-lightPurpleHover);
}

.backgroundColorMauve {
  background-color: var(--color-mauve);
}

.backgroundColorMauveHover {
  background-color: var(--color-mauveHover);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorNotReviewed {
  background-color: var(--color-notReviewed);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorProgressBar {
  background-color: var(--color-progressBar);
}

.backgroundColorSearchHighlight {
  background-color: var(--color-searchHighlight);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorSeparator {
  background-color: var(--color-separator);
}

.backgroundColorSeparatorDisabled {
  background-color: var(--color-separatorDisabled);
}

.backgroundColorSeparatorHover {
  background-color: var(--color-separatorHover);
}

.backgroundColorSeparatorWithShadow {
  background-color: var(--color-separatorWithShadow);
}

.backgroundColorSuccess {
  background-color: var(--color-success);
}

.backgroundColorSuccessDisabled {
  background-color: var(--color-successDisabled);
}

.backgroundColorSuccessHover {
  background-color: var(--color-successHover);
}

.backgroundColorTagBackground {
  background-color: var(--color-tagBackground);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

