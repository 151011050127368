:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-error: #f04c4c;
  --color-errorDisabled: rgba(240, 76, 76, 0.36);
  --color-errorHover: #cf3232;
  --color-facebook: #5e81dc;
  --color-ghost: #bfbfbf;
  --color-google: #7bb0ff;
  --color-hard: #fc881c;
  --color-hardHover: #e17008;
  --color-highlight: #5c61df;
  --color-highlightDisabled: rgba(92, 97, 223, 0.36);
  --color-highlightHover: #2f35c0;
  --color-lightPurple2: #eaecff;
  --color-lightPurple: #f5f6fd;
  --color-lightPurpleHover: rgba(92, 97, 223, 0.16);
  --color-mauve: #8b90f8;
  --color-mauveHover: #767beb;
  --color-menuPopup: #fff;
  --color-notReviewed: #e0e3f5;
  --color-primary: #1f1f1f;
  --color-progressBar: #8f92e0;
  --color-searchHighlight: #96E7CE;
  --color-secondary: #808080;
  --color-separator: #e6e8f0;
  --color-separatorDisabled: rgba(230, 232, 240, 0.36);
  --color-separatorHover: #D9DCE9;
  --color-separatorWithShadow: #f1f1f1;
  --color-success: #4acea6;
  --color-successDisabled: rgba(56, 203, 159, 0.36);
  --color-successHover: #1bb98a;
  --color-tagBackground: #e0e3f5;
  --color-tertiary: #dedde5;
  --color-websiteBackground: #fcfcff;
  --color-white: #ffffff; 
}
