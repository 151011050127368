:root {
  --color-backgroundOverlay-darkMode: rgba(0, 0, 0, 0.3);
  --color-error-darkMode: #f04c4c;
  --color-errorDisabled: rgba(240, 76, 76, 0.36);
  --color-errorHover-darkMode: #cf3232;
  --color-facebook-darkMode: #5e81dc;
  --color-ghost-darkMode: #bfbfbf;
  --color-google-darkMode: #7bb0ff;
  --color-hard-darkMode: #fc881c;
  --color-hardHover-darkMode: #e17008;
  --color-highlight-darkMode: #5c61df;
  --color-highlightDisabled: rgba(92, 97, 223, 0.36);
  --color-highlightHover-darkMode: #2f35c0;
  --color-lightPurple-darkMode: #f5f6fd;
  --color-lightPurple2-darkMode: #eaecff;
  --color-lightPurpleHover: rgba(92, 97, 223, 0.16);
  --color-mauve-darkMode: #8b90f8;
  --color-mauveHover-darkMode: #767beb;
  --color-menuPopup-darkMode: #fff;
  --color-notReviewed-darkMode: #e0e3f5;
  --color-primary-darkMode: #1f1f1f;
  --color-progressBar-darkMode: #8f92e0;
  --color-searchHighlight-darkMode: #96E7CE;
  --color-secondary-darkMode: #808080;
  --color-separator-darkMode: #e6e8f0;
  --color-separatorDisabled: rgba(230, 232, 240, 0.36);
  --color-separatorHover-darkMode: #d9dce9;
  --color-separatorWithShadow-darkMode: #f1f1f1;
  --color-success-darkMode: #4acea6;
  --color-successDisabled: rgba(56, 203, 159, 0.36);
  --color-successHover-darkMode: #1bb98a;
  --color-tagBackground-darkMode: #e0e3f5;
  --color-tertiary-darkMode: #dedde5;
  --color-websiteBackground-darkMode: #fcfcff;
  --color-white-darkMode: #ffffff; 
}
