/* Styles the HTML displayed by the CardDisplay component */

.cardDisplay hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px dashed #e6e8f0;
  margin-bottom: 40px;
  margin-left: -40px;
  margin-top: 40px;
  width: 782px;
}

.cardDisplay img {
  max-width: 100%;
}